/* Style for Privacy Policy */
body {
  font-family: Arial, sans-serif;
}

.container-policy {
  max-width: 800px;
  min-width: 320px;
  width: 80%;
  margin: 0 auto;
  padding: 20px;
}

h1 {
  font-size: 28px;
  margin-bottom: 20px;
  text-align: left;
}

h2 {
  font-size: 24px;
  margin-top: 30px;
  margin-bottom: 10px;
  text-align: left;
}

p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 15px;
}

ul {
  list-style-type: none;
  padding-left: 20px;
}

li {
  margin-bottom: 10px;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .container-policy {
    padding: 10px;
  }

  h1 {
    font-size: 24px;
    
  }

  h2 {
    font-size: 20px;
  }

  p {
    font-size: 14px;
  }
}
